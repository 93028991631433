/**
 * vendor
 */
import React from 'react'
import type {
  GetServerSideProps,
  NextPage,
  GetServerSidePropsContext,
} from 'next'

import { dehydrate, QueryClient } from 'react-query'

import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

/**
 * api
 */
import { productApi } from 'src/api'
import mainCMSApi, { queryMainCMS, IMainCMS } from 'src/api/main-page'

import MainPage from 'src/scenes/main'

const Home: NextPage<{
  data: IMainCMS
  previousRoute?: string
}> = data => {
  return <MainPage {...data} />
}

export const getServerSideProps: GetServerSideProps = async ({
  locale,
}: // req,
GetServerSidePropsContext) => {
  // const previousRoute = req.headers?.referer || null
  const queryClient = new QueryClient()
  // await queryClient.prefetchQuery(['getCategories'], categoryApi.getCategories)
  await queryClient.prefetchQuery(
    [
      'getProducts',
      {
        categorySlug: '',
      },
      false,
    ],
    () => productApi.getProducts(),
  )
  try {
    const contents = await mainCMSApi.getMainCMS({
      ...queryMainCMS,
      locale: locale as string,
    })

    return {
      props: {
        ...(await serverSideTranslations(locale as string, [
          'common',
          'components.header',
          'components.footer',
          'components.moduleCard',
          'components.modal',
          'components.form',
          'components.navigation',
          'pages.index',
        ])),
        dehydratedState: dehydrate(queryClient),
        data: contents,
        // previousRoute,
      },
    }
  } catch (error) {
    return {
      redirect: {
        permanent: false,
        destination: '/404',
      },
    }
  }
}

export default Home
