import React from 'react'
type TOptions = {
  top?: number
}
type TCallback = {
  (visible: boolean): void
}
/**
 * Возвращает TRUE, если проскролено до низа страницы
 */
export function hasScrolledToBottomOfPage(options?: TOptions): boolean {
  let top: number = 300
  const { documentElement } = document
  if (options && options.top) {
    top = options.top
  }
  return documentElement.scrollTop > top
}

function useScrolledToBottomOfPage(cb: TCallback, options?: TOptions): void {
  React.useEffect(() => {
    const handler = () => {
      cb(hasScrolledToBottomOfPage(options))
    }

    document.addEventListener('scroll', handler)

    return () => {
      document.removeEventListener('scroll', handler)
    }
  }, [cb])
}

export default useScrolledToBottomOfPage
