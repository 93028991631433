/**
 * vendor
 */
import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import compose from 'lodash/fp/compose'
import getConfig from 'next/config'
/**
 * context
 */
import { useAppDispatch, useAppState } from 'src/context/app'
/**
 * api
 */
import { useSecureQuery } from 'src/api/secure'
import { IMainCMS } from 'src/api/main-page'
/**
 * ui
 */
import Layout from 'src/components/Layout/Layout'
import { FooterStyle } from 'src/components/Footer'
import { TModal } from 'src/components/Modal'
import { notify, ToastIcon } from 'src/components/Toast'

const LightMainIntro = dynamic(
  () => import('src/scenes/main/components/LightMainIntro'),
)
const LightMainPayment = dynamic(
  () => import('src/scenes/main/components/LightMainPayment'),
)
const Categories = dynamic(
  () => import('src/scenes/main/components/Categories'),
)
const HowToUse = dynamic(() => import('src/scenes/main/components/HowToUse'))
const Confirm = dynamic(() => import('src/scenes/main/components/Confirm'))
const Registration = dynamic(
  () => import('src/scenes/main/components/Registration'),
)
const Reviews = dynamic(() => import('src/scenes/main/components/Reviews'), {
  ssr: false,
})
/**
 * hook
 */
import useHeaders from 'src/hooks/useHeaders'
import useScrolledToBottomOfPage from 'src/hooks/useScrolledToBottomOfPage'
/**
 * constants
 */
import { ALERT_CLOSE_WAIT } from 'src/constants'

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

interface Props {
  data: IMainCMS
  previousRoute?: string
}

const Main: React.FC<Props> = ({ data: contentsData, previousRoute }) => {
  const { meta } = contentsData
  const dispatch = useAppDispatch()

  const { code } = useAppState()
  const { query } = useRouter()
  const { isLoggedIn } = useHeaders()
  // for header background
  const [visibleTopScroll, setVisibleTopScroll] = useState<boolean>(false)
  // for header background
  useScrolledToBottomOfPage(
    (visible: boolean) => {
      setVisibleTopScroll(visible)
    },
    {
      top: 10,
    },
  )

  useEffect(() => {
    if (query.code) {
      dispatch({
        type: 'SET_RESET_PASSWORD_CODE',
        code: query.code as string,
      })
    }
    // from landing page
    if (query.modal && !isLoggedIn) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: true,
        modalType: TModal.LOGIN,
      })
    }
    // from landing page
    if (query.modal && isLoggedIn) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: false,
        modalType: TModal.BLANK,
      })
      // have to remove query ?modal=login?
    }
  }, [query, dispatch, isLoggedIn])

  const { data: checkCodeData } = useSecureQuery.useGetResetPasswordCheck(code)

  // new password code check
  useEffect(() => {
    if (checkCodeData && checkCodeData.data.success && code) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: true,
        modalType: TModal.NEW,
      })
    }
    if (checkCodeData && !checkCodeData.data.success && code) {
      notify({
        icon: ToastIcon.ERROR,
        autoClose: ALERT_CLOSE_WAIT,
        label:
          'Ссылка для смены пароля уже не активна. Запросите восстановление пароля повторно.',
      })
    }
  }, [dispatch, code, checkCodeData])
  // set previous router когда попал не авторизаванно
  useEffect(() => {
    if (previousRoute) {
      dispatch({
        type: 'SET_PREVIOUS_ROUTE',
        previousRoute,
      })
    }
  }, [previousRoute, dispatch])

  return (
    <Layout
      footerStyle={FooterStyle.BLOCK}
      style={{ paddingTop: '0' }}
      headerStyle={{
        style: { backgroundColor: visibleTopScroll ? '#fff' : 'transparent' },
      }}
    >
      <Head>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta property='og:title' content={meta.title} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:image' content={meta.image} />
        <meta property='og:url' content={`${API_URL}`} />
        <link rel='canonical' href={`${API_URL}`} />
      </Head>

      <LightMainIntro />

      <Categories />

      <LightMainPayment />

      <HowToUse />

      <Confirm />

      <Registration />

      <Reviews />
    </Layout>
  )
}

export default compose()(Main)
